<template>
  <div class="container messages">
    <div v-if="message" class="content">
      <h1>Secret message</h1>

      <textarea class='field-value block secret-message form-control'
                v-model="message.message" />

      <div v-if="message.use_passphrase" class="read-passphrase-block">
        The message has been encrypted. Enter the passphrase to read it.

        <input type="text"
               :class="!valid_passphrase ? 'form-control is-invalid' : 'form-control'"
               @input="try_decrypt()"
               id="read-passphrase" v-model="passphrase">
      </div>

      <div class="deleted-notification">
        The message has been deleted from the server, and will no longer be readable after you close this browser
        tab/window.
      </div>
    </div>
    <div v-if="message == null" class="content">
      There is no such message. It has either been read already, or never been sent.
    </div>
  </div>
</template>

<script>

import Toastify from 'toastify-js';

import { aesGcmDecrypt } from '../misc/crypto.js';

import * as bootstrap from "bootstrap";

import axios from "axios";
axios.defaults.headers.get['Accept'] = 'application/json';
const csrfToken = document.querySelector("meta[name=csrf-token]").content;
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

export default {
  name: 'ReadMessage',

  message: null,
  passphrase: '',
  valid_passphrase: false,

  async created() {
    console.log('read message component created');

    this.message = gon.message;
    this.passphrase = '';
    this.valid_passphrase = false;
  },

  data: function () {
    return {
      message: this.message,
      passphrase: this.passphrase,
      valid_passphrase: this.valid_passphrase
    }
  },

  methods: {
    try_decrypt: async function() {
      const plaintext = await aesGcmDecrypt(this.message.message, this.passphrase)
      if (plaintext != null) {
        this.message.message = plaintext;
        this.message.use_passphrase = false;
        this.passphrase= '';
        this.valid_passphrase = true;
      }
      else {
        this.valid_passphrase = false;
      }
    },
  }
}
</script>
