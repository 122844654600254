<template>
  <button class="btn btn-primary" v-on:click="new_message">Send secret</button>

  <div class="modal fade" id="send-message-modal" tabindex="-1"
       aria-labelledby="Send message" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Send secret</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="message-text" data-field-name="message" class="col-form-label">Secret:</label>
              <textarea :class="validation_errors['message'] ? 'form-control is-invalid' : 'form-control'"
                        id="message-text"
                        @input="clear_validations('message')"
                        v-model="message"></textarea>
              <div class="validation-error invalid-feedback" v-for="error in validation_errors['message'] || []">
                {{ error }}
              </div>
            </div>
            <div class="mb-3">
              <label for="expires" class="col-form-label">Expires:</label>
              <select id="expires" class="form-select mb-3"
                      aria-label="Expires" v-model="expires">
                <option selected value="tomorrow">Tomorrow</option>
                <option value="1 week">In 1 week</option>
                <option value="1 month">In 1 month</option>
              </select>
            </div>
            <div class="mb-3 form-check form-switch">
              <label for="encrypt" class="form-check-label">Encrypt with passphrase</label>
              <input class="form-check-input" type="checkbox"
                     id="encrypt"
                     @change="validate_passphrase()"
                     v-model="encrypt">
            </div>
            <div v-if="encrypt" class="mb-3">
              <label for="passphrase" data-field-name="passphrase" class="col-form-label">Passphrase:</label>
              <input type="text"
                     :class="validation_errors['passphrase'] ? 'form-control is-invalid' : 'form-control'"
                     @input="validate_passphrase()"
                     id="passphrase" v-model="passphrase">
              <div class="validation-error invalid-feedback" v-for="error in validation_errors['passphrase'] || []">
                {{ error }}
              </div>
            </div>
            <div v-if="encrypt" class="mb-3 encrypt-notification">
              The message will also be encrypted with the selected
              passphrase. The recipient must know it in order to read
              the message.
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" v-on:click="send_message" class="btn btn-primary">Send secret</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Toastify from 'toastify-js';

import { aesGcmEncrypt } from '../misc/crypto.js';

import { copyToClipboard } from '../misc/util.js';

import * as bootstrap from "bootstrap";

import axios from "axios";
axios.defaults.headers.get['Accept'] = 'application/json';
const csrfToken = document.querySelector("meta[name=csrf-token]").content;
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

export default {
  name: 'SendMessage',

  message: '',
  expires: '',
  encrypt: false,
  passphrase: '',
  validation_errors: {},

  async created() {
    console.log('send message component created');
  },

  data: function () {
    return {
      message: this.message,
      expires: this.expires,
      encrypt: this.encrypt,
      passphrase: this.passphrase,
      validation_errors: this.validation_errors || {}
    }
  },

  methods: {
    clear: function() {
      this.message = '';
      this.validation_errors = {};
      this.encrypt = false;
      this.passphrase = '';
      this.expires = 'tomorrow';
    },

    clear_validations: function(field) {
      delete this.validation_errors[field];
    },

    validate_passphrase: function() {
      if (this.encrypt && this.passphrase.length < 6) {
        this.validation_errors['passphrase'] = ['must be at least 6 characters long']
      }
      else {
        this.clear_validations('passphrase');
      }
    },

    is_valid: function() {
      return Object.keys(this.validation_errors).length == 0;
    },

    new_message: function() {
      console.log('new_message');

      this.clear();
      this.modal = new bootstrap.Modal(document.getElementById('send-message-modal'), {});
      this.modal.show();

      const that = this;
      const modal_element = document.getElementById('send-message-modal');
      modal_element.addEventListener('hidden.bs.modal', function hide_listener(e) {
        console.log('modal hidden');
        that.clear(); // make sure data is not available in JS after close
        modal_element.removeEventListener('hidden.bs.modal', hide_listener); // don't attach this twice
      });
    },

    send_message: function() {
      console.log('send_message');

      if (!this.is_valid()) {
        console.log('invalid');
        return;
      }

      if (this.encrypt) {
        aesGcmEncrypt(this.message, this.passphrase).then((msg) => {
          this.post_message(msg);
        });
      }
      else {
        this.post_message(this.message);
      }
    },

    post_message: function(msg) {
      const that = this;

      axios.post(gon.create_message_path, {
        message: msg,
        expires: this.expires,
        use_passphrase: this.encrypt
      })
     .then(function (response) {
       console.log(response.data);
       that.emitter.emit('message-sent', response.data);

       that.modal.hide();

       copyToClipboard(response.data.url).then(() => {
         Toastify({
           text: "The link to the message has been copied to the clipboard.",
           duration: 5000,
           gravity: "bottom",
           position: "right",
           className: 'success',
           stopOnFocus: true
         }).showToast();
       }).catch(() => {
         that.emitter.emit('show-link-copy', response.data.url);
       });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status == 400) {
          that.validation_errors = error.response.data;
          that.validate_passphrase();
        }
        else {
          Toastify({
            text: "An unexpected error happened while sending the message. Message not sent.",
            duration: 5000,
            gravity: "bottom",
            position: "right",
            className: 'error',
            stopOnFocus: true
          }).showToast();

          that.modal.hide();
        }
      });
    }
  }
}
</script>
