<template>
  <div class="modal fade" id="show-link-copy-modal" tabindex="-1"
       aria-labelledby="Showing secret link" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Secret link</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if="link != null" class="modal-body">
          <form>
            <div class="mb-3 copy-link-from-field">
              <input id="link-data-input" type="text" class="form-control" v-model="link" readonly>
              <button type="button" class="btn btn-success" v-on:click="copy_to_clipboard">Copy</button>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Toastify from 'toastify-js';
import * as bootstrap from "bootstrap";
import { copyElementContentToClipboard } from '../misc/util.js';

export default {
  name: 'ShowLinkCopy',

  link : null,

  async created() {
    console.log('show link copy component created');
  },

  data: function () {
    return {
      link: this.link
    }
  },

  mounted() {
    this.emitter.on('show-link-copy', (data) => {
      this.show_link(data);
    });
  },

  methods: {
    clear: function() {
      this.link = null;
    },

    show_link: function(data) {
      console.log('show_message');

      this.clear();
      this.link = data;
      this.modal = new bootstrap.Modal(document.getElementById('show-link-copy-modal'), {});
      this.modal.show();

      const that = this;
      const modal_element = document.getElementById('show-link-copy-modal');
      modal_element.addEventListener('hidden.bs.modal', function hide_listener(e) {
        console.log('modal hidden');
        that.clear(); // make sure data is not available in JS after close
        modal_element.removeEventListener('hidden.bs.modal', hide_listener); // don't attach this twice
      });
    },

    copy_to_clipboard: function() {
      copyElementContentToClipboard(document.getElementById('link-data-input')).then(() => {
        Toastify({
          text: "The link to the message has been copied to the clipboard.",
          duration: 5000,
          gravity: "bottom",
          position: "right",
          className: 'success',
          stopOnFocus: true
         }).showToast();
      }).catch(() => {
        Toastify({
           text: "Failed to copy the link to the clipboard.",
           duration: 5000,
           gravity: "bottom",
           position: "right",
           className: 'error',
           stopOnFocus: true
         }).showToast();
      });
    }
  }
}
</script>
