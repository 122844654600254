// NOTE: on iOS this only works if initiated from a user action

// return a promise
export function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  // also skip on Safari, due to rules about visible text etc.
  // https://stackoverflow.com/questions/40147676/javascript-copy-to-clipboard-on-safari
  const apple = navigator.userAgent.match(/ipad|iphone/i);

  if ((navigator.clipboard && window.isSecureContext) && !apple) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }
  else {
    // text area method
    let textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = textToCopy;
    textArea.contentEditable = true;
    textArea.readOnly = false;
    // textArea.setAttribute('readonly', 'false');
    // textArea.setAttribute('contenteditable', 'true');

    document.body.appendChild(textArea);

    textArea.focus();

    if (!apple) {
      textArea.select();
    }
    else {
      const range = document.createRange();
      range.selectNodeContents(textArea);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    }

    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}

export function copyElementContentToClipboard(element) {
  // navigator clipboard api needs a secure context (https)
  // also skip on Safari, due to rules about visible text etc.
  // https://stackoverflow.com/questions/40147676/javascript-copy-to-clipboard-on-safari
  const apple = navigator.userAgent.match(/ipad|iphone/i);

  const result = () => {
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();

      if (!apple) {
        const selection = window.getSelection();
        selection.removeAllRanges();
      }
    });
  };

  element.focus();
  if (!apple) {
    element.select();
  }
  else {
    const range = document.createRange();
    range.selectNodeContents(element);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    element.setSelectionRange(0, 999999);
  }

  return result();
}
