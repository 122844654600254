import Toastify from 'toastify-js';
import { createApp } from 'vue'
import SendMessage from '../controllers/send_message.vue'
import ReadMessage from '../controllers/read_message.vue'
import ShowLinkCopy from '../controllers/show_link_copy.vue'
import mitt from 'mitt'

document.addEventListener('DOMContentLoaded', () => {
  // set up flash messages
  for (const flash of gon.flash) {
    const severity = flash[0];
    const message = flash[1];

    console.log(`FLASH ${severity} ${message}`);

    let klass = 'notice';
    if (severity == 'alert') {
      klass = 'error';
    }

    Toastify({
      text: message,
      duration: klass == 'notice' ? 3000 : 5000,
      gravity: "bottom",
      position: "right",
      className: klass,
      stopOnFocus: true
    }).showToast();
  }

  // set up message sending
  const emitter = mitt();
  const sendApp = createApp(SendMessage);
  sendApp.config.globalProperties.emitter = emitter;
  sendApp.mount('#vue-send-message');

  // set up link copy (for iOS)
  const linkApp = createApp(ShowLinkCopy);
  linkApp.config.globalProperties.emitter = emitter;
  linkApp.mount('#vue-copy-link');

  // set up message reading
  const readApp = createApp(ReadMessage);
  readApp.config.globalProperties.emitter = emitter;
  readApp.mount('#vue-read-message');
});
